<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/home/home' }">教师签到活动</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/home/memberlist/memberlist' }">签到名单</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 名单列表 -->
    <el-card class="righttable">
      <i class="el-icon-tickets"></i>
      <span>名单列表</span>
      <el-button type="primary" size="small" style="float:right;" @click="addList()">新增名单</el-button>
      <el-table :data="listData" border stripe style="width: 100%;margin-top:20px;"
        :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column type="index" min-width="80" align="center">
        </el-table-column>
        <el-table-column prop="name" label="名单名称" min-width="100" align="center">
          <template slot-scope="scope">
            <span class="link" @click="openmember(scope.row)">{{
              scope.row.name
            }}</span>
          </template>
        </el-table-column>
        <el-tab5le-column prop="number" label="名单人数" min-width="100" align="center">
        </el-tab5le-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-button type="warning" size="small" @click="gotoEdit(scope.row)">编辑</el-button>
              <el-button type="danger" size="small" @click="deleteList(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper" :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize" :page-sizes="[5, 10, 15]" :total="total"></el-pagination>
      </div>
    </el-card>
    <!-- 查看名单的对话框-->
    <el-dialog title="名单成员" :visible.sync="lookdialogVisible" width="30%">
      <el-table :data="looktableData" height="250" border stripe style="width: 100%">
        <el-table-column type="index" min-width="80" align="center">
        </el-table-column>
        <el-table-column prop="name" label="姓名" min-width="150" align="center">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lookdialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 新增名单的对话框-->
    <el-dialog title="新增名单" :visible.sync="newdialogVisible" width="800px">
      <a @click="downloadxls()" style="color: #419eff; border-bottom: 0.5px dotted #419eff">下载模板</a>
      <!-- action="https://www.xqzjgsu.top:8001/list/excel" -->
      <div style="text-align: center">
        <el-upload class="upload-demo" drag action="https://xiangqian.club:8001/list/excel" :on-change="handleChange"
          :on-success="handleSuccess" :headers="headers" :data="listDataforexcel" name="list" ref="upload"
          accept=".xls,.xlsx">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将xls/xlsx文件拖到此处，或<em>点击上传</em>
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="newdialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页
      formInline: {
        pageNum: 1,
        pageSize: 5
      },
      total: 0,
      //名单列表数据
      listData: [],
      //名单成员数据
      looktableData: [],
      //查看名单成员对话框
      lookdialogVisible: false,
      uid: '',
      //新增名单对话框
      newdialogVisible: false,
      headers: {
        Authorization: 'Bearar ' + sessionStorage.getItem('Authorization')
      },
      listDataforexcel: {
        listName: '',
        uid: ''
      }
    }
  },
  created() {
    this.uid = sessionStorage.getItem('teacheruid')
    this.listDataforexcel.uid = sessionStorage.getItem('teacheruid')
    this.getList()
  },
  methods: {
    //下载模板
    downloadxls() {
      window.location.href = process.env.BASE_URL + '名单模板.xls'
    },
    //获取申请记录表
    async getList() {
      const { data: res } = await this.$http.get('/list/get', {
        params: {
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize,
          uid: this.uid
        }
      })
      if (res != null) {
        // console.log("名单数据",res)
        this.listData = res.data.list
        this.total = res.data.total
      }
    },
    gotoEdit(data) {
      this.$router.push({
        path: '/home/memberlist/editmemberList',
        query: {
          listId: data.id,
          listname: data.name
        }
      })
    },
    addList() {
      this.newdialogVisible = true
    },
    //删除名单
    async deleteList(data1) {
      this.$confirm('此操作将删除选中的成员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.get('/list/del', {
            params: {
              listId: data1.id
            }
          })
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getList()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    //查看名单成员
    async openmember(data1) {
      const { data: res } = await this.$http.get('/list/getMember', {
        params: {
          listId: data1.id
        }
      })
      if (res != null) {
        this.looktableData = res.data
        this.lookdialogVisible = true
      }
    },
    //处理文件上传
    handleChange(file, fileList) {
      var filenamesplit = file.name.split('.')
      this.listDataforexcel.listName = filenamesplit[0]
    },
    //处理文件成功，再次获取数据
    handleSuccess(e) {
      this.getList()
      this.newdialogVisible = false
      if (e.code == 200) {
        this.$message({
          type: 'success',
          message: e.message
        })
      } else {
        this.$message({
          type: 'error',
          message: e.message
        })
      }
    },
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val
      this.getList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.getList()
    }
  }
}
</script>

<style scoped>
.link {
  color: #525dd8;
}

.link:hover {
  color: #8b80dc;
  cursor: pointer;
}
</style>
